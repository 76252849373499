<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <CasesHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p>我姓芦，是一家大型日资企业的在华高管。早在几年前，我就已经通过朋友推荐，在跃迁办理了加拿大魁北克投资移民。由于当初属于魁北克旧例投资移民的后一批申请者，等待时间很久，虽然现在获得直接豁免资格，但在准备投资时，我和太太还是犹豫了，当初办理加拿大投资移民的初衷，无非是想借助加拿大的平台到北美发展，但相较于加拿大，对我来说美国更具有吸引力。既然现在跃迁有非常好的EB-5 项目--纽约四季酒店，加上跃迁文案的专业程度，我们有理由相信转为申请EB-5投资移民是能够直接跨入美国大门，实现美国梦的好途径。 </p>
        <p> 我还是找到了之前的移民顾问陆小姐，请他们为我调出原来的申请文件，在此基础上再补充了一些资金文件，并且顺利的完成了投资。一个月后我们就拿到了EB5的申请档案号。去年圣诞前夕，我们获得了美国移民局I-526申请的批准，这是跃迁给我们的一份好的圣诞礼物。真的很感谢他们的耐心和诚心。 </p>
        <p>项目，纽约四季酒店，加上跃迁文案的专业程度，我们有理由相信转为申请EB-5投资移民是能够直接跨入美国大门，实现美国梦的好途径。2010年加拿大申请的材料在跃迁文案经理陆小姐处妥善完整的保存着，因此文案申请准备相当顺利，在陆小姐的指导下，我们顺利的完成了投资，并且时间递交了进USCIS，于2015年8月获得了档案号。2016年12月圣诞前夕，我们获得了美国移民局I-526申请的批准，这是跃迁给我们的一份好的圣诞礼物。
        </p>
        <p>
          <img loading="lazy" class="aligncenter" src="@/assets/Cases/1/1.jpg">
        </p>

      </div>

      <CasesFooter class="mt-5" :index="info.index"/>
    </div>
  </div>

</template>

<script>
import CasesHeader from "@/components/Cases/CasesHeader";
import CasesFooter from "@/components/Cases/CasesFooter";

export default {
  name: "Cases1",
  components: {CasesFooter, CasesHeader},
  data() {
    return {
      info: this.$route.meta,
    }
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>